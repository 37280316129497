import {useTranslation} from "react-i18next";

import Container from "../../components/container/Container";
import Row from "../../components/grid/Row";
import Col from "../../components/grid/Col";
import InputContactForm from "../../components/UI/Form/InputContactForm";
import TextareaContactForm from "../../components/UI/Form/TextareaContactForm";
import SolidButton from "../../components/UI/Buttons/SolidButton";
import Ball from "../../components/Ball";

import './ContactPage.css';
import TelegramLogo from "../../assets/icons/TelegramLogo";
import InstagramLogo from "../../assets/icons/InstagramLogo";
import useInput from "../../hooks/use-input";
import {isEmail} from "../../helpers/Validator";
import {sendCallbackForm} from "../../api/Contacts";
import ErrorMessage from "../../components/UI/Error/ErrorMessage";
import {useState} from "react";
import ComponentLoading from "../../components/loading/ComponentLoading";
import LanguageSetter from "../../components/container/LanguageSetter";

const ContactPage = (props) => {
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState('');
    const [isSending, setIsSending] = useState(false);

    const {
        value: FLName,
        isValid: enteredFLNameIsValid,
        reset: FLNameReset,
        valueChangeHandler: FLNameChangedHandler,
        inputBlurHandler: FLNameBlurHandler
    } = useInput(value => value.trim() !== '');

    const {
        value: email,
        isValid: enteredEmailIsValid,
        reset: emailReset,
        valueChangeHandler: emailChangedHandler,
        inputBlurHandler: emailBlurHandler
    } = useInput(value => isEmail(value));

    const {
        value: message,
        isValid: enteredMessageIsValid,
        reset: messageReset,
        valueChangeHandler: messageChangedHandler,
        inputBlurHandler: messageBlurHandler
    } = useInput(value => value.trim() !== '');

    const formIsValid = enteredEmailIsValid && enteredFLNameIsValid && enteredMessageIsValid;

    const sendForm = (e) => {
        e.preventDefault();
        if (formIsValid) {
            setIsSending(true);
            sendCallbackForm(FLName, email, message)
                .then(res => res.json())
                .then(data => {
                    setIsSending(false);
                    if (data.error === 'ok') {
                        messageReset();
                        emailReset();
                        FLNameReset();
                    } else {
                        setErrorMessage(t('contactPage.forms.somethingWrong'));
                    }
                })
        }
    };

    return (
        <LanguageSetter lang={props.lang}>
            <main className='p-relative'>
                <section className='contact' itemScope itemType="http://schema.org/ContactPage">
                    <Container>
                        <Row>
                            <Col sm={12} md={6} lg={5}>
                                <h1 className='contact__title primary-text' itemProp="headline">{t('contactPage.header')}</h1>
                                <p className='grey-text contact__info' itemProp="description">
                                    {t('contactPage.workHoursText')} <br />
                                    {t('contactPage.workHoursInfo')} <br />
                                    {t('contactPage.workHoursInfo2')}
                                </p>

                                <p><a href='tel:+37379556644' className='primary-text contact__tel' itemProp="telephone">+373 795 56 644</a></p>

                                <h2 className='contact__subtitle primary-text'>Social Media</h2>
                                <div className='d-flex align-items-center' itemProp="sameAs">
                                    <InstagramLogo />&nbsp;&nbsp;<TelegramLogo />
                                </div>
                            </Col>
                            <Col sm={12} md={12} lg={7}>
                                <div className='contact-form__body'>
                                    <form onSubmit={sendForm} itemProp="potentialAction" itemScope itemType="http://schema.org/CommunicateAction">
                                        <Col sm={12} md={5}>
                                            <InputContactForm id='name'
                                                              value={FLName}
                                                              onChange={FLNameChangedHandler}
                                                              onBlur={FLNameBlurHandler}
                                                              label={t('contactPage.forms.name')}
                                                              required
                                                              itemProp={'object'}
                                            />
                                        </Col>
                                        <Col sm={12} md={5}>
                                            <InputContactForm id='email'
                                                              value={email}
                                                              onChange={emailChangedHandler}
                                                              onBlur={emailBlurHandler}
                                                              label='Email'
                                                              required
                                                              itemProp={'object'}
                                            />
                                        </Col>
                                        <Col sm={12} md={10}>
                                            <TextareaContactForm id='message'
                                                                 value={message}
                                                                 onChange={messageChangedHandler}
                                                                 onBlur={messageBlurHandler}
                                                                 label={t('contactPage.forms.message')}
                                                                 required
                                                                 itemProp={'object'}
                                            />
                                        </Col>
                                        <ErrorMessage errorMessage={errorMessage} />
                                        <div className='contact-form__control'>
                                            { isSending && <ComponentLoading justifyContent='flex-start' /> }
                                            { !isSending &&
                                                <SolidButton className='w-25' text={t('contactPage.forms.sendButton')} primary disabled={!formIsValid} /> }
                                        </div>

                                    </form>
                                </div>
                            </Col>
                        </Row>
                        <Ball
                            background='#FE0098'
                            top='-100px'
                            left={0}
                        />
                        <Ball
                            background='#FE7A00'
                            top='300px'
                            left={0}
                        />
                        <Ball
                            background='#E4B948'
                            top={0}
                            right={0}
                        />
                    </Container>
                </section>
            </main>
        </LanguageSetter>
    );
};

export default ContactPage;
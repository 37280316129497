import {addToCartHandler} from "../../../pages/CartPage/CartHandlers";
import {useContext} from "react";
import CartContext from "../../../store/cart-context";
import LoginContext from "../../../store/login-context";

import {useTranslation} from "react-i18next";

import './AddToCart.css';

const AddToCartButton = (props) => {
    const cartCtx = useContext(CartContext);
    const loginCtx = useContext(LoginContext);
    const { t } = useTranslation();

    const addToCartButtonHandler = () => {
        addToCartHandler(props.id, cartCtx, loginCtx, props.amount);
        cartCtx.openCart();
    }

    return (
        <span className='solid-button fw-light primary-button add-to-cart' onClick={addToCartButtonHandler}>
            {t('add_to_card_text')}
        </span>
    );
};

export default AddToCartButton;
import {memo} from "react";
import {useTranslation} from "react-i18next";
import OwlCarousel from 'react-owl-carousel';

import Container from "../../container/Container";
import TopBrand from "./TopBrand";
import SectionCenterHeader from "../../UI/Headers/SectionCenterHeader";
import Line from "../../UI/Line";

import './TopBrands.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Gizeh from '../../../assets/img/top_brands_logos/gizeh_logo.png';
import MacBaren from '../../../assets/img/top_brands_logos/macBaren_logo.png';
import Peterson from '../../../assets/img/top_brands_logos/peterson_logo.png';
import Zippo from '../../../assets/img/top_brands_logos/zippo_logo.png';
import AlladinLogo from "../../../assets/img/top_brands_logos/aladin.png";
import CasaturrentLogo from "../../../assets/img/top_brands_logos/Casaturrent_logo.png";
import ClubmasterLogo from "../../../assets/img/top_brands_logos/Clubmaster.png";
import StanleyLogo from "../../../assets/img/top_brands_logos/Stanley_logo.png";

const TopBrands = () => {
    const { t } = useTranslation();

    const carouselOptions = {         
        loop: true,
        autoplay:true,
        autoplayTimeout:2500,
        autoplayHoverPause:true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        dots:false,
        responsive: {
            0: {
                items: 1.15,
                margin: 20,
                dots: false,
            },
            768: {
                items: 4,
                margin: 10,
                dots: true
            }
        },
    };  

    return (
        <section className='top-brands'>
        <Container>
            <SectionCenterHeader>{t('topBrands.header')}</SectionCenterHeader>             
                <div className='brands-carousel'  itemScope itemType="http://schema.org/ItemList">
                <OwlCarousel className='owl-theme' {...carouselOptions}>                   
                    <div className='item' itemProp="itemListElement" itemScope itemType="http://schema.org/Brand">
                        <span itemProp={'name'} content={'Gizeh'} />
                        <TopBrand logo={Gizeh} url='Gizeh' />
                    </div>
                    <div className='item' itemProp="itemListElement" itemScope itemType="http://schema.org/Brand">
                        <span itemProp={'name'} content={'Mac baren'} />
                        <TopBrand logo={MacBaren} url='Mac baren' />
                    </div>
                    <div className='item' itemProp="itemListElement" itemScope itemType="http://schema.org/Brand">
                        <span itemProp={'name'} content={'Peterson'} />
                        <TopBrand logo={Peterson} url='Peterson' />
                    </div>
                    <div className='item' itemProp="itemListElement" itemScope itemType="http://schema.org/Brand">
                        <span itemProp={'name'} content={'Zippo'} />
                        <TopBrand logo={Zippo} url='Zippo' />  
                    </div>
                    <div className='item' itemProp="itemListElement" itemScope itemType="http://schema.org/Brand">
                        <span itemProp={'name'} content={'Aladin'} />
                        <TopBrand logo={AlladinLogo} url='Aladin' />
                    </div>
                    <div className='item' itemProp="itemListElement" itemScope itemType="http://schema.org/Brand">
                        <span itemProp={'name'} content={'Casa_Turrent'} />
                        <TopBrand logo={CasaturrentLogo} url='Casa_Turrent' />
                    </div>
                    <div className='item' itemProp="itemListElement" itemScope itemType="http://schema.org/Brand">
                        <span itemProp={'name'} content={'Clubmaster'} />
                        <TopBrand logo={ClubmasterLogo} url='Clubmaster' />
                    </div>
                    <div className='item' itemProp="itemListElement" itemScope itemType="http://schema.org/Brand">
                        <span itemProp={'name'} content={'Stanley'} />
                        <TopBrand logo={StanleyLogo} url='Stanley' />
                    </div>
                </OwlCarousel>
                </div>
                <Line />
        </Container>
        </section>
    );

};

//export default TopBrands;
export default memo(TopBrands);
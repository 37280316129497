import {Fragment, useContext, useEffect, useState} from "react";
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import LoginContext from "../../../store/login-context";
import {useTranslation} from "react-i18next";
import CartContext from "../../../store/cart-context";
import {getSubcategoryLinks} from "../../../api/Subcategory";
import {getProductByNameAsync} from "../../../api/ProductInfo";
import {getCurrentUser} from "../../../api/Account";

import Container from "../../container/Container";
import Row from "../../grid/Row";
import CartWidget from "./CartWidget";
import BurgerMenu from "./BurgerMenu";

import './Header.css';
import PhoneIcon from "../../../assets/icons/PhoneIcon";
import BurgerButtonIcon from "../../../assets/icons/BurgerButtonIcon";
import AvatarIcon from "../../../assets/icons/AvatarIcon";
import Logo from "../../../assets/icons/Logo";
import CartIcon from "../../../assets/icons/CartIcon";

const Header = () => {
    const loginCtx = useContext(LoginContext);
    const cartCtx = useContext(CartContext);
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const [openedBurgerMenu, setOpenedBurgerMenu] = useState(false);

    const openBurgerMenuHandler = () => setOpenedBurgerMenu(prevState => !prevState);

    const avatarIconLink = loginCtx.isLoggedIn() ? '/profile' : '/login';

    useEffect(() => {
        const fetchData = async () => {
            if (loginCtx.isLoggedIn() && !loginCtx.loaded) {
                const response = await getCurrentUser(loginCtx);
                if (response.error !== undefined)
                {
                    loginCtx.logout();
                }
                loginCtx.updateData(response.isVIP, response.firstname);
            }
        }
        fetchData().then(() => {});
    }, [loginCtx]);

    const toggleLanguage = async () => {
        let link;
        let futureLanguage;
        if (i18n.resolvedLanguage === 'ru') {
            link = '/ro';
            futureLanguage = 'ro';
        } else {
            link = '/ru';
            futureLanguage = 'ru';
        }

        const linkParts = location.pathname.split('/');
        if (linkParts.length > 3 && linkParts[2] === 'shop') {
            link += '/' + linkParts[2];
            const subcategoryData = await getSubcategoryLinks(linkParts[3]);
            link += '/' + subcategoryData['link_name_' + futureLanguage];

            if (linkParts.length === 5) {
                const productData = await getProductByNameAsync(linkParts[4]);
                link += '/' + productData['product_name_link_' + futureLanguage];
            }
        } else {
            link += location.pathname.slice(3);
        }

        navigate(link);
    }

    let firstLinkClasses = 'header__link';
    if (location.pathname.length > 3) {
        firstLinkClasses += ' child-selected';
    }

    let profileButton = <Link to={'/' + i18n.resolvedLanguage + '/login'} className='header__login'>{t('navigation.login')}</Link>;
    if (loginCtx.isLoggedIn()) {
        let VIPBadge = null;
        let highlightClass = '';
        if (loginCtx.isVIP) {
            VIPBadge = <span className={'white-text text-small'}>VIP</span>
            highlightClass = ' highlight';
        }
        profileButton = <Link to={'/' + i18n.resolvedLanguage + '/profile'} className={'header__login' + highlightClass}>{VIPBadge} {t('navigation.profile')}</Link>;
    }

    return (
        <Fragment>
            <header>
                <Container>
                    <Row className='justify-content-between align-items-center' style={{flexWrap: 'nowrap'}}>
                        <div className='d-flex align-items-center until-lg'>
                            <button className='header__button' onClick={openBurgerMenuHandler}><BurgerButtonIcon /></button>
                            <Link to={'/' + i18n.resolvedLanguage + avatarIconLink} className='header__button'><AvatarIcon /></Link>
                        </div>
                        <Link to={'/' + i18n.resolvedLanguage} className='header__logo-container'><Logo className='header__logo' /></Link>
                        <div className='d-flex align-items-center from-lg'>
                            <nav className='header__links'>
                                <NavLink to={'/' + i18n.resolvedLanguage} className={firstLinkClasses}>{t('navigation.home')}</NavLink>
                                <NavLink to={'/' + i18n.resolvedLanguage + '/shop'} className='header__link'>{t('navigation.shop')}</NavLink>
                                <NavLink to={'/' + i18n.resolvedLanguage + '/contacts'} className='header__link'>{t('navigation.contacts')}</NavLink>
                                <NavLink to={'/' + i18n.resolvedLanguage + '/info'} className='header__link'>{t('navigation.info')}</NavLink>
                            </nav>
                            {profileButton}
                            <button className='header__link-button primary-text' onClick={toggleLanguage}>
                                {i18n.resolvedLanguage === 'ru' ? 'RO' : 'RU'}
                            </button>
                            <button className='header__link-button header__link-cart primary-text' onClick={cartCtx.toggleCart}><CartIcon /></button>
                            <a href='tel:+37379556644' className='header__link-button secondary-text'><PhoneIcon /></a>
                        </div>
                        <div className='d-flex align-items-center until-lg'>
                            <button className='header__button header__link-cart' onClick={cartCtx.toggleCart}><CartIcon /></button>
                            <a href='tel:+37379556644' className='header__button header__link-cart secondary-text'><PhoneIcon style={{width: '16px'}} /></a>
                        </div>
                    </Row>
                </Container>
            </header>
            <CSSTransition in={openedBurgerMenu} timeout={300} classNames='burger-menu' mountOnEnter unmountOnExit>
                <BurgerMenu onClose={openBurgerMenuHandler} lngChange={toggleLanguage} lng={i18n.resolvedLanguage} />
            </CSSTransition>
            <CSSTransition in={cartCtx.isOpened} timeout={300} classNames='cart-widget' mountOnEnter unmountOnExit>
                <CartWidget onClose={cartCtx.closeCart} />
            </CSSTransition>
            <div style={{marginTop: '120px'}} />
        </Fragment>
    );
};

export default Header;
import {useNavigate, useParams} from "react-router-dom";
import {isValidPassword} from "../../helpers/Validator";
import useInput from "../../hooks/use-input";
import {changePasswordRestore} from "../../api/Account";
import {useContext, useEffect, useState} from "react";
import LoginContext from "../../store/login-context";
import {useTranslation} from "react-i18next";

import LanguageSetter from "../../components/container/LanguageSetter";
import LinkButton from "../../components/UI/Buttons/LinkButton";
import Container from "../../components/container/Container";
import Row from "../../components/grid/Row";
import Col from "../../components/grid/Col";
import Input from "../../components/UI/Form/Input";
import SolidButton from "../../components/UI/Buttons/SolidButton";
import Ball from "../../components/Ball";
import ErrorMessage from "../../components/UI/Error/ErrorMessage";

import './Authorization.css';

const ChangePassword = (props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const {r, e} = useParams();
    const [errorMessage, setErrorMessage] = useState('');
    const loginCtx = useContext(LoginContext);

    useEffect(() => {
        if (loginCtx.isLoggedIn()) {
            navigate(-2);
        }
    }, []);

    const {
        value: newPassword,
        isValid: enteredNewPasswordIsValid,
        hasError: newPasswordInputHasError,
        valueChangeHandler: newPasswordChangedHandler,
        inputBlurHandler: newPasswordBlurHandler
    } = useInput(value => isValidPassword(value));

    const {
        value: newPassword2,
        isValid: enteredNewPassword2IsValid,
        hasError: newPassword2InputHasError,
        reset: newPassword2Reset,
        valueChangeHandler: newPassword2ChangedHandler,
        inputBlurHandler: newPassword2BlurHandler
    } = useInput(value => isValidPassword(value) && value === newPassword);

    const changePasswordButtonHandler = async (event) => {
        event.preventDefault();
        const response = await changePasswordRestore(newPassword, r, e);
        if (response.error === 'ok') {
            navigate('/' + i18n.resolvedLanguage + '/login');
        } else {
            setErrorMessage(t('loginForms.error.link'));
            newPassword2Reset();
        }
    };

    const formIsValid = enteredNewPasswordIsValid && enteredNewPassword2IsValid;
    return  (
        <LanguageSetter lang={props.lang}>
            <main>
                <section className='p-relative'>
                    <Container>
                        <Row className='auth justify-content-center align-items-center'>
                            <Col sm={12} lg={6}>
                                <Row className='justify-content-center'>
                                    <div className='d-flex justify-content-end w-100'>
                                        <LinkButton onClick={() => navigate(-1)} className='light-text desktop-only' text={t('loginForms.backButton')} />
                                    </div>
                                    <Col sm={12} lg={8}>
                                        <h1 className='auth__title primary-text text-center'>{t('loginForms.changePasswordHeader')}</h1>
                                        <form className='auth__padding-big' onSubmit={changePasswordButtonHandler}>
                                            <Input
                                                type='password'
                                                placeholder={t('loginForms.newPassword')}
                                                value={newPassword}
                                                onChange={newPasswordChangedHandler}
                                                onBlur={newPasswordBlurHandler}
                                                isError={newPasswordInputHasError}
                                            />
                                            <Input
                                                type='password'
                                                placeholder={t('loginForms.repeatPassword')}
                                                value={newPassword2}
                                                onChange={newPassword2ChangedHandler}
                                                onBlur={newPassword2BlurHandler}
                                                isError={newPassword2InputHasError}
                                            />
                                            {errorMessage !== '' && <ErrorMessage errorMessage={errorMessage} />}
                                            <div className='d-flex justify-content-center auth__padding-big'>
                                                <SolidButton className='w-75' text={t('loginForms.changePasswordButton')} primary disabled={!formIsValid} />
                                            </div>
                                        </form>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Ball
                            background='#FE0098'
                            bottom={0}
                            left={0}
                        />
                        <Ball
                            background='#E4B948'
                            top={0}
                            right={0}
                        />
                    </Container>
                </section>
            </main>
        </LanguageSetter>
    );
};

export default ChangePassword;
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import Container from "./container/Container";
import Row from "./grid/Row";
import Col from "./grid/Col";
import HollowButton from "./UI/Buttons/HollowButton";
import SolidButton from "./UI/Buttons/SolidButton";

import './Cookies.css';

const Cookies = (props) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const acceptCookiesHandler = () => {
        localStorage.setItem('cookies', '1');
        props.onAccept();
    };

    const goToInfo = () => {
        navigate('/' + i18n.resolvedLanguage + '/info');
    }

    return (
        <aside className='cookies_body'>
            <Container>
                <Row>
                    <Col col={12}>
                        <h2 className='white-text'>Cookies</h2>
                    </Col>
                    <Col xs={12} lg={8}>
                        <p className='light-text'>{t('modals.cookies.text')}</p>
                    </Col>
                    <Col xs={12} lg={4} className='d-flex align-items-center'>
                        <HollowButton text={t('modals.cookies.infoButton')} className='mr-2' onClick={goToInfo} />
                        <SolidButton primary text={t('modals.cookies.acceptButton')} onClick={acceptCookiesHandler} />
                    </Col>
                </Row>
            </Container>
        </aside>
    );
};

export default Cookies;
import './ContactForm.css';

const TextareaContactForm = (props) => {
    return (
        <div className='input-contact-container'>
            <label className='input-contact__label grey-text' htmlFor={props.id}>{props.label}</label>
            <textarea
                className='input-container__control'
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
                required={props.required}
                id={props.id}
                name={props.label}
                itemProp={props.itemProp}
            />
        </div>
    );
};

export default TextareaContactForm;
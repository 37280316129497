import SearchProduct from "./SearchProduct";
import CategorySelection from "./CategorySelection";
import TopBrands from "../../components/blocks/TopBrands/TopBrands";
import CEO from "../../components/blocks/CEO/CEO";
import Instagram from "../../components/blocks/Instagram/Instagram";
import LanguageSetter from "../../components/container/LanguageSetter";

import './CategorySelectPage.css';

const CategorySelectPage = (props) => {
    return (
      <LanguageSetter lang={props.lang}>
          <main>
              <SearchProduct />
              <CategorySelection />
              <TopBrands />
              <CEO />
              <Instagram />
          </main>
      </LanguageSetter>
    );
};

export default CategorySelectPage;
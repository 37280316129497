import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

import Col from "../../components/grid/Col";
import TriangleClosed from "../../assets/icons/TriangleClosed";
import VIPBadge from "../../components/UI/VIPBadge";

import './CategorySlider.css';

const CategorySlider = (props) => {
    const { i18n } = useTranslation();
    const sliderMenuItems = props.subcategories.map(item => {
        const link = `${item["link_name_" + i18n.resolvedLanguage]}`;
        let highlight = '';
        let badge = null;
        if (item.isVIP === "1")
        {
            highlight = ' highlight';
            badge = (
              <VIPBadge small />
            );
        }
        return (
            <li
                key={item.id}
                className={'white-text' + highlight}
                itemProp="itemListElement"
                itemScope
                itemType="http://schema.org/ListItem"
            ><TriangleClosed />{badge}<Link itemProp={'url'} to={link}>{item['title_' + i18n.resolvedLanguage]}</Link></li>
        );
    });
    return (
        <Col col={12} className='category-option__slider'>
            <ul itemProp={'itemListElement'} itemScope itemType="http://schema.org/ItemList">
                {sliderMenuItems}
            </ul>
        </Col>
    );
};

export default CategorySlider;
import {useTranslation} from "react-i18next";

import HollowButton from "../../components/UI/Buttons/HollowButton";
import Ball from "../../components/Ball";
import Container from "../../components/container/Container";
import LanguageSetter from "../../components/container/LanguageSetter";

import './SuccessPage.css';
import SuccessIconPng from "../../assets/img/success-icon.png";

const SuccessPage = (props) => {
    const { t, i18n } = useTranslation();
    return (
        <LanguageSetter lang={props.lang}>
            <main className='p-relative'>
                <Container>
                    <div className='success-page d-flex justify-content-center align-items-center'>
                        <div className='text-center'>
                            <h1 className='white-text'>{t('successPage.header')}</h1>
                            <p className='gray-text success-page__info'>{t('successPage.message1')}</p>
                            <img src={SuccessIconPng} width='54' height='54' alt='Success icon' />
                            <p className='gray-text success-page__info'>{t('successPage.message2')}</p>
                            <div className='d-flex align-items-center justify-content-between' style={{marginTop: '30px'}}>
                                <HollowButton primary text={t('successPage.mainPageButton')} to={'/' + i18n.resolvedLanguage + '/'} />
                                <HollowButton primary text={t('successPage.profileButton')} to={'/' + i18n.resolvedLanguage + '/profile'} />
                            </div>
                        </div>
                    </div>
                    <Ball
                        background='#FE0098'
                        bottom='0'
                        right={0}
                    />
                    <Ball
                        background='#FE7A00'
                        bottom='0'
                        left={0}
                    />
                    <Ball
                        background='#E4B948'
                        top={0}
                        right={0}
                    />
                </Container>
            </main>
        </LanguageSetter>
    );
};

export default SuccessPage;
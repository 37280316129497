import React from 'react';

const LoginContext = React.createContext({
    isVIP: false,
    firstname: '',
    loaded: false,
    updateData: () => { },
    isLoggedIn: () => { },
    getSessionId: () => { },
    login: () => { },
    logout: () => { }
});

export default LoginContext;
import './Badge.css';

const VIPBadge = (props) => {
    let className = '';
    if (props.small) {
        className += ' small';
    }
    if (props.highlight) {
        className += ' highlight';
    }

    return (
        <span>
            <span className={'badge text-small white-text ' + className}>VIP</span>
        </span>
    );
};

export default VIPBadge;
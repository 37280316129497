import {useContext, useEffect, useReducer, useState} from "react";
import {getCategories} from "../../api/Categories";
import {categoryReducer, initialCategoryReducer} from "../../reducers/CategoryReducer";
import {useTranslation} from "react-i18next";
import LoginContext from "../../store/login-context";

import Container from "../../components/container/Container";
import Row from "../../components/grid/Row";
import CategoryOption from "./CategoryOption";
import Ball from "../../components/Ball";
import ComponentLoading from "../../components/loading/ComponentLoading";

import tobacco from '../../assets/img/category/category1.png';
import tobaccoXS from '../../assets/img/category/category1xs.png';
import hookah from '../../assets/img/category/category2.png';
import hookahXS from '../../assets/img/category/category2xs.png';
import cigar from '../../assets/img/category/category3.png';
import cigarXS from '../../assets/img/category/category3xs.png';
import cigarillo from '../../assets/img/category/category4.png';
import cigarilloXS from '../../assets/img/category/category4xs.png';
import lighter from '../../assets/img/category/category5.png';
import lighterXS from '../../assets/img/category/category5xs.png';
import eShisha from '../../assets/img/category/category6.png';
import eShishaXS from '../../assets/img/category/category6xs.png';
import cigarettes from '../../assets/img/category/category7.png';
import cigarettesXS from '../../assets/img/category/category7xs.png';
import pipe from '../../assets/img/category/category8.png';
import pipeXS from '../../assets/img/category/category8xs.png';
import './CategorySelection.css';

const CategorySelection = () => {
    const { i18n } = useTranslation();
    const loginCtx = useContext(LoginContext);
    const [activeId, setActiveId] = useState(null);
    const [state, dispatch] = useReducer(categoryReducer, initialCategoryReducer);

    useEffect(() => {
        getCategories(dispatch, loginCtx.getSessionId());
    }, [loginCtx]);

    const changeActiveHandler = (id) => {
        setActiveId(id);
    }

    const categoryImages = [
        {
            image: tobacco,
            imageXS: tobaccoXS
        },
        {
            image: hookah,
            imageXS: hookahXS
        },
        {
            image: cigar,
            imageXS: cigarXS
        },
        {
            image: cigarillo,
            imageXS: cigarilloXS
        },
        {
            image: lighter,
            imageXS: lighterXS
        },
        {
            image: eShisha,
            imageXS: eShishaXS
        },
        {
            image: cigarettes,
            imageXS: cigarettesXS
        },
        {
            image: pipe,
            imageXS: pipeXS
        }
    ];

    let categoryComponents = <ComponentLoading height={'100vh'} />;
    if (state.categoryLoaded) {
        categoryComponents = state.categories.map((category, index) => {
            category = category[1];
            return (
                <CategoryOption
                    key={category.id}
                    id={category.id}
                    active={activeId}
                    title={category['title_' + i18n.resolvedLanguage]}
                    img={categoryImages[index].image}
                    imgMob={categoryImages[index].imageXS}
                    onClick={changeActiveHandler}
                    subcategories={category.subcategories}
                />
            );
        });
    }

    return (
        <section className='p-relative' itemScope itemType="http://schema.org/ItemList">
            <Container>
                <Row>
                    {state.categoryLoaded ? categoryComponents.slice(0, 4) : categoryComponents}
                </Row>
                <Row>
                    {state.categoryLoaded && categoryComponents.slice(4)}
                </Row>
                <Ball
                    background='#E4B948'
                    top='0'
                    right='0'
                />
                <Ball
                    background='#FE7A00'
                    bottom='0'
                    left='0'
                />
            </Container>
        </section>
    );
};

export default CategorySelection;
import {Fragment, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const LanguageSetter = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {i18n} = useTranslation();

    useEffect(() => {
        if (!props.lang) {
            navigate('/' + i18n.resolvedLanguage + location.pathname);
        } else {
            i18n.changeLanguage(props.lang);
            document.documentElement.lang = props.lang;
        }
    }, []);

    return (
        <Fragment>
            {props.children}
        </Fragment>
    );
};

export default LanguageSetter;
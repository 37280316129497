import {apiAddress} from "./APILink";

const getCategories = (dispatch, session_id = null) => {
    dispatch({type: 'SetLoadingCategory'});

    let currentUser = '';
    if (session_id) {
        currentUser = '?currentUser=' + session_id;
    }

    fetch(apiAddress + 'api/scripts/Category.php' + currentUser, {method: 'get'})
        .then(response => response.json())
        .then(data => {
            dispatch({type: 'LoadCategories', payload: data});
        });
};

export {getCategories};
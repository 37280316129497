import {sendCodeToEmail} from "../../api/Code";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {isEmail} from "../../helpers/Validator";

import Backdrop from "../blocks/Header/Backdrop";
import Row from "../grid/Row";
import Col from "../grid/Col";
import useInput from "../../hooks/use-input";
import InputContactForm from "../UI/Form/InputContactForm";
import SolidButton from "../UI/Buttons/SolidButton";
import CrossIcon from "../../assets/icons/CrossIcon";
import Timer from "../blocks/Timer";
import ErrorMessage from "../UI/Error/ErrorMessage";
import Spinner from "../loading/Spinner";

import './Modal.css';

const TimerModal = (props) => {
    const { t } = useTranslation();
    const [message, setMessage] = useState('');
    const [showLoad, setShowLoad] = useState(false);

    const {
        value: email,
        isValid: enteredEmailIsValid,
        reset: emailReset,
        valueChangeHandler: emailChangedHandler,
        inputBlurHandler: emailBlurHandler
    } = useInput(value => isEmail(value));

    const formIsValid = enteredEmailIsValid && !showLoad;

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        if (formIsValid) {
            setShowLoad(true);
            const response = await sendCodeToEmail(email);
            if (response.error === 'ok') {
                setMessage(t('modals.timer.message.success'));
            } else if (response.error === 2) {
                setMessage(t('modals.timer.message.emailIsUsed'));
            }
            emailReset();
            setShowLoad(false);
        }
    };

    return (
        <Backdrop className='d-flex align-items-center justify-content-center'>
            <aside className='modal__body-sm'>
                <div className='modal__cross d-flex justify-content-end' onClick={props.onClose}>
                    <CrossIcon />
                </div>
                <Row className='justify-content-center'>
                    <Col sm={12} md={7} lg={7} style={{paddingRight: '40px'}}>
                        <h2 className='modal__title primary-text modal__mb-lg'>
                            {t('modals.timer.headerPrimary')}
                            <span className='secondary-text'> {t('modals.timer.headerSecondary')}</span>
                        </h2>
                        <p className='modal__info light-text text-center'>
                            {t('modals.timer.timerHeader')}
                        </p>
                        <Timer
                            seconds={props.discountTimerSeconds}
                        />
                    </Col>
                    <Col sm={12} md={5} lg={5}>
                        <p className='modal__info light-text'>
                            {t('modals.timer.formHeader')}
                        </p>
                        <form onSubmit={onSubmitHandler}>
                            <InputContactForm id='email'
                                              value={email}
                                              onChange={emailChangedHandler}
                                              onBlur={emailBlurHandler}
                                              label={t('modals.timer.emailLabel')}
                                              required
                            />
                            <SolidButton
                                text={t('modals.timer.actionButton')}
                                primary
                                className='w-100 modal__mt-lg mb-1'
                                disabled={!formIsValid}
                            />
                            {showLoad && <Spinner width={'20px'} height={'20px'} />}
                            <ErrorMessage errorMessage={message} />
                        </form>
                    </Col>
                </Row>
            </aside>
        </Backdrop>
    );
};

export default TimerModal;
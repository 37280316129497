import React, {memo} from "react";
import OwlCarousel from "react-owl-carousel";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './ProductImageCarousel.css';

const ProductImageCarousel = (props) => {
    const angle = '<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1000 1000" width="30" height="20" xml:space="preserve">\n' +
        '            <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>\n' +
        '            <g><path d="M968.5,343.6c28.7-28.7,28.7-75.3,0-104c-28.7-28.7-75.3-28.7-104,0L544.9,559.1c-12.9,10-28.7,15.6-45.2,15.6c-16.5,0-32.4-5.6-45.2-15.6L135.6,240.2c-28.7-28.7-75.3-28.7-104,0c-28.7,28.7-28.7,75.3,0,104l416.1,416.1c13.8,13.8,32.5,21.5,52,21.5c19.5,0,38.2-7.7,52-21.5L968.5,343.6z"/></g>\n' +
        '        </svg>';

    const bigImageCarouselProperties = {
        loop: false,
        margin: 16,
        dots: false,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        nav: true,
        navText: [angle, angle],
        items: 1,
        responsive: {
            0: {
                margin: 9,
                nav: false
            },
            768: {
                margin: 13
            }
        },
    };
    const navCarouselProperties =  {
        loop: false,
        dots: false,
        nav: true,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        navText: [angle, angle],
        items: 5,
        center: true,
        responsive: {
            0: {
                margin: 9
            },
            768: {
                margin: 13
            }
        },
    };

    let bigImageCarousel = React.createRef();
    let navImageCarousel = React.createRef();

    const carouselItems = props.carouselInfo.map((img, index) => {
        return (
            <div className='product-image-carousel__item item' key={index}>
                <img src={img.image} width='530' height='530' alt={img.alt} title={img.title} />
            </div>
        );
    });

    const changeBigCarouselHandler = () => {
        if (navImageCarousel.current != null) {
            const carouselItems = navImageCarousel.current.$ele[0].childNodes[0].childNodes[0].childNodes;
            let index = 0;
            for (; index < carouselItems.length; index++) {
                if (carouselItems[index].classList.contains("center")) break;
            }
            bigImageCarousel.current.to(index, 500);
        }
    };

    return (
        <div className='product-image-carousel'>
            <OwlCarousel className='product-image-carousel__big-image owl-theme' ref={bigImageCarousel} {...bigImageCarouselProperties}>
                {carouselItems}
            </OwlCarousel>
            <OwlCarousel className='product-image-carousel__nav owl-theme' ref={navImageCarousel} onTranslated={changeBigCarouselHandler} {...navCarouselProperties}>
                {carouselItems}
            </OwlCarousel>
        </div>
    );
};

export default memo(ProductImageCarousel);
import {Fragment, useContext} from "react";
import {Link} from "react-router-dom";
import LoginContext from "../../../store/login-context";

import Backdrop from "./Backdrop";

import './BurgerMenu.css';
import CrossIcon from "../../../assets/icons/CrossIcon";
import {useTranslation} from "react-i18next";

const BurgerMenu = (props) => {
    const { t, i18n } = useTranslation();
    const loginCtx = useContext(LoginContext);

    let loggedContent = (
        <Fragment>
            <p className='gray-text burger-menu__info'>
                {t('navigation.burgerMenuLoginLabel')}
            </p>
            <div className='d-flex align-items-center justify-content-between'>
                <Link to={'/' + i18n.resolvedLanguage + '/login'} className='burger-menu__button-link burger-menu__primary-button' onClick={props.onClose}>{t('navigation.login')}</Link>
                <Link to={'/' + i18n.resolvedLanguage + '/registration'} className='burger-menu__button-link burger-menu__primary-hollow-button' onClick={props.onClose}>{t('navigation.register')}</Link>
            </div>
        </Fragment>
    );
    if (loginCtx.isLoggedIn()) {
        loggedContent = (
            <Fragment>
                <div className='d-flex align-items-center justify-content-between'>
                    <Link to={'/' + i18n.resolvedLanguage + '/profile'} className='burger-menu__button-link burger-menu__primary-button' onClick={props.onClose}>{t('navigation.profile')}</Link>
                </div>
            </Fragment>
        );
    }

    return (
        <Fragment>
            <nav className='burger-menu'>
                <div onClick={props.onClose}><CrossIcon /></div>
                <button className='primary-text burger-menu__button-link-transparent' onClick={props.lngChange}>
                    {props.lng === 'ru' ? 'RO' : 'RU'}
                </button>
                <Link to={'/' + i18n.resolvedLanguage + '/'} className='burger-menu__link' onClick={props.onClose}>{t('navigation.home')}</Link>
                <Link to={'/' + i18n.resolvedLanguage + '/shop'} className='burger-menu__link' onClick={props.onClose}>{t('navigation.shop')}</Link>
                <Link to={'/' + i18n.resolvedLanguage + '/contacts'} className='burger-menu__link' onClick={props.onClose}>{t('navigation.contacts')}</Link>
                <Link to={'/' + i18n.resolvedLanguage + '/info'} className='burger-menu__link' onClick={props.onClose}>{t('navigation.info')}</Link>
                {
                    loggedContent
                }
            </nav>
            <Backdrop onClick={props.onClose} />
        </Fragment>
    );
};

export default BurgerMenu;
import {useContext, useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import useInput from "../../hooks/use-input";
import {isEmail, isValidPassword} from "../../helpers/Validator";
import {login} from "../../api/Account";
import {emptyLocalCart, fetchCart} from "../../api/Cart";
import CartContext from "../../store/cart-context";
import LoginContext from "../../store/login-context";
import {useTranslation} from "react-i18next";

import LinkButton from "../../components/UI/Buttons/LinkButton";
import Container from "../../components/container/Container";
import Row from "../../components/grid/Row";
import Col from "../../components/grid/Col";
import Input from "../../components/UI/Form/Input";
import SolidButton from "../../components/UI/Buttons/SolidButton";
import Ball from "../../components/Ball";
import ErrorMessage from "../../components/UI/Error/ErrorMessage";
import LanguageSetter from "../../components/container/LanguageSetter";

import './Authorization.css';

const Login = (props) => {
    const { t , i18n} = useTranslation();
    let navigate = useNavigate();
    const cartCtx = useContext(CartContext);
    const loginCtx = useContext(LoginContext);

    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (loginCtx.loaded) navigate(-1);
    }, []);

    const {
        value: email,
        isValid: enteredEmailIsValid,
        hasError: emailInputHasError,
        valueChangeHandler: emailChangedHandler,
        inputBlurHandler: emailBlurHandler
    } = useInput(value => isEmail(value));

    const {
        value: password,
        isValid: enteredPasswordIsValid,
        hasError: passwordInputHasError,
        reset: passwordReset,
        valueChangeHandler: passwordChangedHandler,
        inputBlurHandler: passwordBlurHandler
    } = useInput(value => isValidPassword(value));

    const validForm = enteredEmailIsValid && enteredPasswordIsValid;

    const loginButtonHandler = async (e) => {
        e.preventDefault();
        if (validForm) {
            const response = await login(email, password);
            if (response.error === 'ok') {
                setErrorMessage('');
                loginCtx.login(response.session_id, response.isVIP, response.firstname);
                fetchCart(cartCtx, loginCtx);
                emptyLocalCart();
                navigate(-1);
            } else if (response.error === 1) {
                setErrorMessage(t('loginForms.error.emptyFields'));
            } else if (response.error === 2) {
                setErrorMessage(t('loginForms.error.emailValidation'));
            } else if (response.error === 3) {
                setErrorMessage(t('loginForms.error.passwordLength'));
            } else if (response.error === 4) {
                passwordReset();
                setErrorMessage(t('loginForms.error.login'));
            }
        }
    };

    const goRestorePassword = () => {
        navigate('/' + i18n.resolvedLanguage + '/restore');
    };

    return (
        <LanguageSetter lang={props.lang}>
            <main>
                <section className='p-relative'>
                    <Container>
                        <Row className='auth justify-content-center align-items-center'>
                            <Col sm={12} lg={6}>
                                <Row className='justify-content-center'>
                                    <div className='d-flex justify-content-end w-100'>
                                        <LinkButton onClick={() => navigate(-1)} className='light-text desktop-only' text={t('loginForms.backButton')} />
                                    </div>
                                    <Col sm={12} lg={8}>
                                        <h1 className='auth__title primary-text text-center'>{t('loginForms.loginHeader')}</h1>
                                        <form className='auth__padding-big' onSubmit={loginButtonHandler}>
                                            <Input
                                                type='email'
                                                placeholder='Email'
                                                value={email}
                                                onChange={emailChangedHandler}
                                                onBlur={emailBlurHandler}
                                                isError={emailInputHasError}
                                            />
                                            <Input
                                                type='password'
                                                placeholder={t('loginForms.password')}
                                                value={password}
                                                onChange={passwordChangedHandler}
                                                onBlur={passwordBlurHandler}
                                                isError={passwordInputHasError}
                                            />
                                            <div>
                                                <LinkButton type='button' className='auth__control auth__padding-small light-text text-underline' onClick={goRestorePassword} text={t('loginForms.forgotPasswordLink')} />
                                            </div>
                                            {errorMessage !== '' && <ErrorMessage errorMessage={errorMessage} />}
                                            <div className='d-flex justify-content-center auth__padding-big'>
                                                <SolidButton onClick={loginButtonHandler} className='w-75' text={t('loginForms.loginButton')} primary disabled={!validForm} />
                                            </div>
                                        </form>
                                        <div className='d-flex justify-content-center'>
                                            <LinkButton onClick={() => navigate('/' + i18n.resolvedLanguage + '/registration')} className='auth__control auth__padding-small light-text text-underline' text={t('loginForms.registrationLink')} />
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Ball
                            background='#FE0098'
                            bottom={0}
                            left={0}
                        />
                        <Ball
                            background='#E4B948'
                            top={0}
                            right={0}
                        />
                    </Container>
                </section>
            </main>
        </LanguageSetter>
    );
};

export default Login;
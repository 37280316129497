import {useTranslation} from "react-i18next";
import {Fragment, useContext} from "react";
import { priceProcessing } from '../../helpers/Processors';
import CartContext from "../../store/cart-context";
import {Link} from "react-router-dom";
import LoginContext from "../../store/login-context";
import {
    decreaseCartItemHandler,
    deleteCartItemHandler,
    increaseCartItemHandler
} from "../../pages/CartPage/CartHandlers";

import LinkButton from "../UI/Buttons/LinkButton";
import CrossIcon from "../../assets/icons/CrossIcon";
import Line from "../UI/Line";
import Col from "../grid/Col";

import './CardEntryCard.css';
import VIPBadge from "../UI/VIPBadge";

const CartEntryCard = (props) => {
    const { i18n } = useTranslation();
    const cartCtx = useContext(CartContext);
    const loginCtx = useContext(LoginContext);

    let priceBlock = <span className='primary-text'>{priceProcessing(props.price)}</span>;
    if (props.discount) {
        priceBlock = (
            <Fragment>
                <span className='secondary-text'>{priceProcessing(props.discount)}</span>
                <span className='primary-text old-price'>{priceProcessing(props.price)}</span>
            </Fragment>
        );
    }

    const badge = props.isVIP === '1' ? (
        <span className={'mr-1'}>
            <VIPBadge small />
        </span>
    ) : null;

    return (
        <Fragment>
            <article className='cart-entry d-flex align-items-center justify-content-between'>
                <LinkButton
                    text={<CrossIcon />}
                    onClick={() => deleteCartItemHandler(props.id, cartCtx, loginCtx)}
                />
                <img src={props.img} alt={props.img_alt} title={props.img_title} />
                <Col col={6}>
                    <h2 className='cart-entry__title white-text'>
                        <Link to={`/${i18n.resolvedLanguage}/shop/${props.subcategory_name}/${props.product_name_link}`}>{badge}{props.title}</Link>
                    </h2>
                    <div className='cart-entry__price until-lg'>
                        {priceBlock}
                    </div>
                </Col>
                <div className='cart-entry__control d-flex align-items-center'>
                    <LinkButton
                        className='white-text'
                        text='-'
                        onClick={() => decreaseCartItemHandler(props.id, cartCtx, loginCtx)}
                    />
                    <span className='white-text'>{props.count}</span>
                    <LinkButton
                        className='white-text'
                        text='+'
                        onClick={() => increaseCartItemHandler(props.id, cartCtx, loginCtx)}
                    />
                </div>
                <div className='cart-entry__price from-lg'>
                    {priceBlock}
                </div>
            </article>
            <Line dark />
        </Fragment>
    );
};

export default CartEntryCard;
import {useNavigate} from "react-router-dom";
import {isEmail} from "../../helpers/Validator";
import {restore} from "../../api/Account";
import useInput from "../../hooks/use-input";
import {useContext, useEffect} from "react";
import LoginContext from "../../store/login-context";
import {useTranslation} from "react-i18next";

import LinkButton from "../../components/UI/Buttons/LinkButton";
import Container from "../../components/container/Container";
import Row from "../../components/grid/Row";
import Col from "../../components/grid/Col";
import Input from "../../components/UI/Form/Input";
import SolidButton from "../../components/UI/Buttons/SolidButton";
import Ball from "../../components/Ball";
import LanguageSetter from "../../components/container/LanguageSetter";

import './Authorization.css';

const Restore = (props) => {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const loginCtx = useContext(LoginContext);

    useEffect(() => {
        if (loginCtx.isLoggedIn()) {
            navigate(-1);
        }
    }, []);

    const {
        value: email,
        isValid: enteredEmailIsValid,
        hasError: emailInputHasError,
        reset: emailReset,
        valueChangeHandler: emailChangedHandler,
        inputBlurHandler: emailBlurHandler
    } = useInput(value => isEmail(value));

    const restoreButtonHandler = (e) => {
        e.preventDefault();
        restore(email);
        emailReset();
    };

    return  (
        <LanguageSetter lang={props.lang}>
            <main>
                <section className='p-relative'>
                    <Container>
                        <Row className='auth justify-content-center align-items-center'>
                            <Col sm={12} lg={6}>
                                <Row className='justify-content-center'>
                                    <div className='d-flex justify-content-end w-100'>
                                        <LinkButton onClick={() => navigate(-1)} className='light-text desktop-only' text={t('loginForms.backButton')} />
                                    </div>
                                    <Col sm={12} lg={8}>
                                        <h1 className='auth__title primary-text text-center'>{t('loginForms.restoreHeader')}</h1>
                                        <p className='auth__info light-text'>
                                            {t('loginForms.restoreText')}
                                        </p>
                                        <form className='auth__padding-big' onSubmit={restoreButtonHandler}>
                                            <Input
                                                type='email'
                                                placeholder='Email'
                                                value={email}
                                                onChange={emailChangedHandler}
                                                onBlur={emailBlurHandler}
                                                isError={emailInputHasError}
                                            />
                                            <div className='d-flex justify-content-center auth__padding-big'>
                                                <SolidButton className='w-75' text={t('loginForms.restoreButton')} primary disabled={!enteredEmailIsValid} />
                                            </div>
                                            <div className='d-flex justify-content-center'>
                                                <p className='dark-text auth__control text-center'>
                                                    {t('loginForms.restoreInfo')}
                                                </p>
                                            </div>
                                        </form>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Ball
                            background='#FE0098'
                            bottom={0}
                            left={0}
                        />
                        <Ball
                            background='#E4B948'
                            top={0}
                            right={0}
                        />
                    </Container>
                </section>
            </main>
        </LanguageSetter>
    );
};

export default Restore;
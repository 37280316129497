import {apiAddress} from "./APILink";

const getPromoCarousel = (dispatch) => {
    dispatch({type: 'SetLoadingPromoCarousel'});
    fetch(apiAddress + 'api/scripts/PromoCarousel.php', {method: 'get'})
        .then(response => response.json())
        .then(data => {
            dispatch({type: 'LoadPromoCarousel', payload: data});
        });
};

const getNewProductsCarousel = (dispatch, session_id = null, id = null) => {
    dispatch({type: 'SetLoadingNewCarousel'})

    let currentUser = '';
    if (session_id) {
        currentUser = '?currentUser=' + session_id;
    }

    fetch(apiAddress + 'api/scripts/NewProductCarousel.php' + currentUser, {method: 'get'})
        .then(response => response.json())
        .then(data => {
            if (id != null)
                data = data.filter(item => item.product_id !== id);
            dispatch({type: 'LoadNewCarousel', payload: data});
        });
};

const getPopularProductsCarousel = (dispatch, session_id = null) => {
    dispatch({type: 'SetLoadingPopularCarousel'});

    let currentUser = '';
    if (session_id) {
        currentUser = '?currentUser=' + session_id;
    }

    fetch(apiAddress + 'api/scripts/PopularProductCarousel.php' + currentUser, {method: 'get'})
        .then(response => response.json())
        .then(data => {
            dispatch({type: 'LoadPopularCarousel', payload: data});
        });
};

const getDiscountProductsCarousel = (dispatch, session_id = null) => {
    dispatch({type: 'SetLoadingDiscountCarousel'});

    let currentUser = '';
    if (session_id) {
        currentUser = '?currentUser=' + session_id;
    }

    fetch(apiAddress + 'api/scripts/DiscountProductCarousel.php' + currentUser, {method: 'get'})
        .then(response => response.json())
        .then(data => {
            dispatch({type: 'LoadDiscountCarousel', payload: data});
        });
};

export {getPromoCarousel, getNewProductsCarousel, getPopularProductsCarousel, getDiscountProductsCarousel};
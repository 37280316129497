import {useNavigate} from "react-router-dom";
import {useContext, useEffect, useReducer, useState} from "react";
import CartContext from "../../store/cart-context";
import {priceProcessing} from "../../helpers/Processors";
import {initialMainPageReducer, mainPageReducer} from "../../reducers/MainPageReducer";
import {getPopularProductsCarousel} from "../../api/Carousels";
import {useTranslation} from "react-i18next";
import {getDiscountValue} from "../../api/GlobalDiscount";
import LoginContext from "../../store/login-context";
import {getCodeByName} from "../../api/Code";
import useInput from "../../hooks/use-input";

import PageName from "../../components/UI/Headers/PageName";
import Container from "../../components/container/Container";
import Row from "../../components/grid/Row";
import Col from "../../components/grid/Col";
import Breadcrumbs from "../../components/UI/Breadcrumbs";
import Line from "../../components/UI/Line";
import CartEntryCard from "../../components/cards/CartEntryCard";
import SolidButton from "../../components/UI/Buttons/SolidButton";
import Input from "../../components/UI/Form/Input";
import ProductCarousel from "../../components/carousels/ProductCarousel";
import SidebarHeader from "../../components/UI/Headers/SidebarHeader";
import CartInfoLine from "../../components/UI/CartInfoLine";
import SidebarContainer from "../../components/container/SidebarContainer";
import Ball from "../../components/Ball";
import EmptyCart from "../../components/UI/Error/EmptyCart";
import ComponentLoading from "../../components/loading/ComponentLoading";
import HollowButton from "../../components/UI/Buttons/HollowButton";
import LanguageSetter from "../../components/container/LanguageSetter";

import './CartPage.css';

const CartPage = (props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const cartCtx = useContext(CartContext);
    const loginCtx = useContext(LoginContext);

    const [codeError, setCodeError] = useState(false);
    const [code, setCode] = useState(null);
    const [state, dispatch] = useReducer(mainPageReducer, initialMainPageReducer);
    const [discountValue, setDiscountValue] = useState(0);

    useEffect(() => {
        getPopularProductsCarousel(dispatch, loginCtx.getSessionId());
        const fetchDiscount = async () => {
            const response = await getDiscountValue(loginCtx);
            if (response.error === 'ok') {
                setDiscountValue(response.value);
            }
        };
        fetchDiscount();
    }, [loginCtx]);

    const breadcrumbHierarchy = [
        {
            name: t('cart.breadcrumbs.shop'),
            url: '/' + i18n.resolvedLanguage + '/shop'
        },
        {
            name: t('cart.breadcrumbs.cart')
        }
    ];

    const {
        value: tmpCode,
        valueChangeHandler: CodeChangedHandler,
        inputBlurHandler: CodeBlurHandler
    } = useInput(() => true);

    const checkCode = async () => {
        const res = await getCodeByName(tmpCode, loginCtx);
        if (res.error === 'ok') {
            setCode(res.code);
        }
        if (res.code === null && tmpCode !== '') {
            setCodeError(true);
        } else {
            setCodeError(false);
        }
    };

    let cartItems = <EmptyCart />;
    if (cartCtx.items.length > 0) {
        cartItems = cartCtx.items.map(item => (
            <CartEntryCard
                key={item.id}
                id={item.id}
                product_name_link={item['product_name_link_' + i18n.resolvedLanguage]}
                subcategory_id={item.subcategory_id}
                subcategory_name={item['subcategory_name_' + i18n.resolvedLanguage]}
                title={item['product_name_' + i18n.resolvedLanguage]}
                price={item.price}
                discount={item.discount}
                count={item.amount}
                img={item.image}
                img_alt={item.alt}
                img_title={item.title}
                isVIP={item.isVIP}
            />
        ));
        
    }

    const arr = cartCtx.items.map(({ discount }) =>  discount);
    const sumarr = arr.reduce((a, b) => Math.trunc(a) + Math.trunc(b), 0);
   
   //  console.log(cartCtx.items.length + ' ' + sum_count + ' ' + sumarr + ' ' + sumarr);
    // 

    const itemNumber = cartCtx.items.length;
    const totalPrice = priceProcessing(+cartCtx.totalAmount);
    
    //let totalPriceDiscounted = +cartCtx.totalAmount * (1 - discountValue / 100);
    let totalPriceDiscounted = +cartCtx.totalAmount; 
    totalPriceDiscounted = totalPriceDiscounted - sumarr;

    //scenariu 1
    if (discountValue !== null && code === null) {
        if(sumarr) { // !!!!!!!!!!!!!!!!!!!!!!!!!!!
            //totalPriceDiscounted *= (1 - discountValue / 100);
        } else {
            totalPriceDiscounted *= (1 - discountValue / 100);
        }
        
    }
    //scenariu 2
    if (code !== null) {
       // sk
        totalPriceDiscounted *= (1 - code.discount / 100);
    } 
    // Fin
    totalPriceDiscounted = totalPriceDiscounted + sumarr;

    let freeDelivery = true;
    if (totalPriceDiscounted < 300) { // de la 300
        freeDelivery = false;
        totalPriceDiscounted += 50;
    }
    totalPriceDiscounted = priceProcessing(totalPriceDiscounted);
    // const validPurchase = cartCtx.totalAmount < 200 || codeError;
    const validPurchase = codeError;

    const proceedToCheckoutHandler = () => {
        if (code !== null) sessionStorage.setItem('code', code.id);
        navigate('/' + i18n.resolvedLanguage + '/checkout');
    };

    return (
        <LanguageSetter lang={props.lang}>
            <main className='p-relative'>
                <PageName m0>{t('cart.pageTitle')}</PageName>
                <Container>
                    <Row>
                        <Col col={12}>
                            <Breadcrumbs hierarchy={breadcrumbHierarchy} />
                        </Col>
                        <Col sm={12} md={12} lg={8}>
                            <Line dark />
                            {cartItems}
                        </Col>
                        <Col sm={12} md={12} lg={4}>
                            <div className='cart-info'>
                                <SidebarHeader text={t('cart.sidebarHeader')} />
                                <SidebarContainer>
                                    {itemNumber > 0 && <CartInfoLine title={t('cart.sidebarHeader')} price={totalPrice} />}
                                    {discountValue !== 0 && <CartInfoLine title={t('cart.globalDiscount')} price={'-' + discountValue + '%'} />}
                                    {code !== null && <CartInfoLine title={code.name} price={'-' + code.discount + '%'} />}
                                    {!freeDelivery && <CartInfoLine title={t('cart.delivery')} price={'50 MDL'} />}
                                    {/*<p className='light-text cart-info__info'>{t('cart.message')}</p>*/}
                                    <Input type='text'
                                           placeholder={t('cart.promoPlaceholder')}
                                           onChange={CodeChangedHandler}
                                           onBlur={CodeBlurHandler}
                                           gold
                                           value={tmpCode}
                                           isError={codeError}/>
                                    <HollowButton text={t('cart.couponButton')} onClick={checkCode} className='mt-2' />
                                    <CartInfoLine title={t('cart.totalSum')} price={totalPriceDiscounted} className='cart-info__total' />
                                    <div className='d-flex justify-content-center'>
                                        <SolidButton className='cart-info__buyButton' onClick={proceedToCheckoutHandler} primary text={t('cart.makeOrderButton')} disabled={validPurchase} />
                                    </div>
                                </SidebarContainer>
                            </div>
                        </Col>
                        <Col col={12} className='cart-carousel-margin'>
                            {state.popularLoaded ? (
                                <div className='margin'>
                                    <ProductCarousel
                                        title={t('carousels.popularCarouselHeader')}
                                        carouselInfo={state.popularCarouselInfo}
                                    />
                                </div>
                            ) : <ComponentLoading height={'60vh'} />}
                        </Col>
                    </Row>
                    <Ball
                        background='#FE0098'
                        bottom='600px'
                        right={0}
                    />
                    <Ball
                        background='#FE7A00'
                        bottom='600px'
                        left={0}
                    />
                    <Ball
                        background='#E4B948'
                        top={0}
                        right={0}
                    />
                </Container>
            </main>
        </LanguageSetter>
    );
};

export default CartPage;
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {priceProcessing} from "../../helpers/Processors";

import AddToCart from "../UI/Buttons/AddToCart";

import './ProductCard.css';

const ProductCard = (props) => {
    const {i18n} = useTranslation();
    const price = priceProcessing(props.price);
    let discount = <div className='discount__margin' />;
    let oldPriceClass = '';
    if (props.discount !== null) {
        discount = (
            <>
                <span itemProp={'priceCurrency'} content={'MDL'} />
                <span className='product-card__price fw-bold secondary-text' itemProp={'price'} content={props.discount}>{priceProcessing(props.discount)}</span>
            </>
        );
        oldPriceClass = 'old-price';
    }
    const src = props.image;
    const url = '/' + i18n.resolvedLanguage + '/shop/' + props.subcategory_name + '/' + props.product_name_link;

    return (
      <article className='product-card' itemScope itemType={'http://schema.org/Product'}>
          <Link to={url} className='product-card__block-link'>
              <div className='product-card__image'>
                  <img src={src} width='262' height='262' alt={props.image_alt} title={props.image_title} itemProp={'image'} />
              </div>
              <div className='product-card__padding'>
                  <p className='product-card__title light-text' itemProp={'name'}>{props.title}</p>
                  <p className='product-card__category dark-text' itemProp={'category'}>{props.category}</p>
                  <div itemProp={'offers'} itemScope itemType={'http://schema.org/Offer'}>
                      <div className='discount__block' itemProp="priceSpecification" itemScope itemType="http://schema.org/PriceSpecification">
                          {discount}
                      </div>
                      <div>
                          <span itemProp={'priceCurrency'} content={'MDL'} />
                          <span className={`product-card__price fw-bold primary-text ${oldPriceClass}`} itemProp={'price'} content={props.price}> {price}</span>
                      </div>
                  </div>
              </div>
          </Link>
          <div className='product-card__controls product-card__padding'>              
              <AddToCart id={props.id} />
          </div>
      </article>
    );
};

export default ProductCard;
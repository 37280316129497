import LoginContext from "./login-context";
import {useReducer} from "react";

const defaultLoginState = {
    isVIP: false,
    firstname: '',
    loaded: false
};

const loginReducer = (state = defaultLoginState, action) => {
    if (action.isVIP === '1' || action.isVIP === '0') {
        action.isVIP = action.isVIP === '1';
    }

    switch (action.type) {
        case 'UPDATE_DATA':
            return {
                ...state,
                isVIP: action.isVIP,
                firstname: action.firstname,
                loaded: true
            };

        case 'LOGIN':
            return {
                ...state,
                isVIP: action.isVIP,
                firstname: action.firstname,
                loaded: true
            };

        case 'LOGOUT':
            return {
                ...state,
                isVIP: false,
                firstname: '',
                loaded: false
            };

        default:
            return {
                ...state
            };
    }
};

const LoginProvider = props => {
    const [loginState, dispatchLoginAction] = useReducer(loginReducer, defaultLoginState);

    const updateData = (isVIP, firstname) => {
        dispatchLoginAction({ type: 'UPDATE_DATA', firstname, isVIP });
    };

    const isLoggedIn = () => {
        return localStorage.getItem('loggedIn') !== null;
    };

    const loginHandler = (session_id, isVIP, firstname) => {
        localStorage.setItem('loggedIn', session_id);
        dispatchLoginAction({ type: 'LOGIN', firstname, isVIP });
    };

    const logoutHandler = () => {
        localStorage.removeItem('loggedIn');
        dispatchLoginAction({ type: 'LOGOUT' });
    };

    const getSessionIdHandler = () => {
        return localStorage.getItem('loggedIn');
    };

    const loginContext = {
        isVIP: loginState.isVIP,
        firstname: loginState.firstname,
        loaded: loginState.loaded,
        updateData: updateData,
        isLoggedIn: isLoggedIn,
        getSessionId: getSessionIdHandler,
        login: loginHandler,
        logout: logoutHandler
    };

    return (
        <LoginContext.Provider value={loginContext}>
            {props.children}
        </LoginContext.Provider>
    );
};

export default LoginProvider;
import './Feature.css';

const Feature = (props) => {
    return (
        <div className='mainPage-feature d-flex' itemProp="itemListElement" itemScope itemType="http://schema.org/ListItem">
            <div className='mainPage-feature__icon' itemProp={'image'}>{props.icon}</div>
            <div className='mainPage-feature__info'>
                <h3 className='mainPage-feature__title light-text' itemProp={'name'}>{props.title}</h3>
                <p className='mainPage-feature__text light-text' itemProp={'description'}>
                    {props.text}
                </p>
            </div>
        </div>
    );
};

export default Feature;
import {Fragment} from "react";
import {Link} from "react-router-dom";

import './HollowButton.css';

const HollowButton = (props) => {
    let classes = 'hollow-button fw-light';
    if (props.primary) classes += ' primary-button';
    if (props.secondary) classes += ' secondary-button';
    else {
        classes += ' primary-button';
    }
    if (props.className) classes += ' ' + props.className;

    let content = (<button className={classes} onClick={props.onClick}>{props.text}</button>);
    if (props.to) content = (<Link itemProp={props.itemProp} to={props.to} className={classes}>{props.text}</Link>);
    if (props.href) content = (<a itemProp={props.itemProp} target={props.target} href={props.href} className={classes}>{props.text}</a>);

    return (
        <Fragment>
            {content}
        </Fragment>
    );
};

export default HollowButton;
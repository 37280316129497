import {apiAddress} from "./APILink";

const getBreadcrumbsPath = (dispatch, name) => {
    dispatch({type: 'SetLoadingBreadcrumbs'});
    fetch(apiAddress + 'api/scripts/Subcategory.php?name=' + name, {method: 'get'})
        .then(response => response.json())
        .then(data => {
            dispatch({type: 'LoadBreadcrumbs', payload: data});
        });
}

export {getBreadcrumbsPath};
import {Link} from "react-router-dom";

import SidebarContainer from "../../components/container/SidebarContainer";
import Line from "../../components/UI/Line";
import DiscountInfoLine from "./DiscountInfoLine";

import './DiscountInfo.css';
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {getDiscounts} from "../../api/GlobalDiscount";

const DiscountInfo = () => {
    const { t } = useTranslation();
    const [discountInfo, setDiscountInfo] = useState([]);

    useEffect(() => {
        const fetchDiscountData = async () => {
            setDiscountInfo(await getDiscounts());
        };
        fetchDiscountData();
    }, []);

    let discountInfoLines = <DiscountInfoLine min={0} max='...' discount={0} />
    if (discountInfo.length > 0) {
        discountInfoLines = discountInfo.map((discount) => {
            const maxValue = discount.max === null ? '...' : discount.max;
            return <DiscountInfoLine key={discount.id} min={discount.min} max={maxValue} discount={discount.discount_amount} />;
        });
    }

    return (
        <aside className='discount-info'>
            <SidebarContainer>
                <h2 className='white-text discount-info__title'><span className='secondary-text'>%</span>{t('profilePage.discountHeader')}</h2>
            </SidebarContainer>
            <Line dark />
            <SidebarContainer>
                {discountInfoLines}
            </SidebarContainer>
            <Line dark />
            <SidebarContainer>
                <p className='dark-text'>
                    {t('profilePage.discountInfo1')} <Link className='dark-text' to='/'> {t('profilePage.discountInfo2')}</Link>
                </p>
            </SidebarContainer>
        </aside>
    );
};

export default DiscountInfo;
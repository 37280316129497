import {useTranslation} from "react-i18next";
import {useContext, useEffect, useState} from "react";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {Routes, Route, useLocation} from "react-router-dom";
import {fetchCart} from "./api/Cart";
import CartContext from "./store/cart-context";
import LoginContext from "./store/login-context";
import ScrollToTop from "./components/ScrollToTop";

import Header from "./components/blocks/Header/Header";
import MainPage from "./pages/MainPage/MainPage";
import CategorySelectPage from "./pages/CategorySelectPage/CategorySelectPage";
import CategoryBrowserPage from "./pages/CategoryBrowser/CategoryBrowserPage";
import ProductPage from "./pages/ProductPage/ProductPage";
import CartPage from "./pages/CartPage/CartPage";
import Checkout from "./pages/Checkout/Checkout";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import Login from "./pages/Authorization/Login";
import Registration from "./pages/Authorization/Registration";
import Restore from "./pages/Authorization/Restore";
import ContactPage from "./pages/Contacts/ContactPage";
import InfoPage from "./pages/InfoPage/InfoPage";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import SuccessPage from "./pages/SuccessPage/SuccessPage";
import Footer from "./components/blocks/Footer/Footer";
import AgeWarningModal from "./components/modals/AgeWarningModal";
import ChangePassword from "./pages/Authorization/ChangePassword";
import Cookies from "./components/Cookies";
import TimerModal from "./components/modals/TimerModal";
import FloatButton from "./components/UI/Buttons/FloatButton";
import ScrollToTopButton from "./components/UI/ScrollToTopButton";

import './App.css';

function App() {
    const {i18n} = useTranslation();
    const [isAgeChecked, setIsAgeChecked] = useState(localStorage.getItem('checked') === '1');
    const [areCookiesAccepted, setAreCookiesAccepted] = useState(localStorage.getItem('cookies') === '1');
    const [timerSeconds, setTimerSeconds] = useState(localStorage.getItem('discountTimer') ?? 900);
    const [isDiscountModalOpened, setDiscountModalOpened] = useState(false);
    const cartCtx = useContext(CartContext);
    const loginCtx = useContext(LoginContext);
    const location = useLocation();

    const warningModalCloseHandler = () => {
        setIsAgeChecked(true);
        localStorage.setItem('checked', '1');
    };

    const acceptCookiesHandler = () => {
        setAreCookiesAccepted(true);
    }

    const toggleDiscountModal = () => {
        setDiscountModalOpened(prevState => !prevState);
    };

    useEffect(() => {
        const fetchData = async () => {
            fetchCart(cartCtx, loginCtx);
        };
        fetchData();
    }, []);

    useEffect(() => {
        let interval = null;
        if (timerSeconds > 0) {
            interval = setInterval(() => {
                setTimerSeconds(seconds => {
                    localStorage.setItem('discountTimer', '' + (seconds - 1));
                    return seconds - 1;
                })
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [timerSeconds]);

    const metadata = {
        title_ro: 'Torsedor - magazin online de produse din tutun de cea mai bună calitate!',
        title_ru: 'Torsedor – онлайн магазин  лучших табачных изделий!',
        description_ro: 'Torsedor - magazin online de tutun. Sunați ☎️ 0795 56 644 O gamă largă de cigarillos, trabacuri, tutun pentru țigări, pipe și narghilea, brichete și alte accesorii pentru fumat.',
        description_ru: 'Torsedor – онлайн магазин табачных изделий. Звоните ☎️ 0795 56 644 Большой выбор сигарилл, сигар, табаков для сигарет, трубок и кальянов, а так же зажигалок и прочих курительных аксессуаров.'
    }

  return (
        <HelmetProvider>
            <Helmet>
                <title>{metadata['title_' + i18n.resolvedLanguage]}</title>
                <meta name="title" content={metadata['title_' + i18n.resolvedLanguage]} />
                <meta name="description" content={metadata['description_' + i18n.resolvedLanguage]} />
            </Helmet>
            <ScrollToTop />
            <Header />
            <TransitionGroup>
                <CSSTransition timeout={450} classNames='fade' key={location.key}>
                    <Routes location={location}>
                        <Route path='ro'>
                            <Route index element={<MainPage lang={'ro'} />} />
                            <Route path='shop' element={<CategorySelectPage lang={'ro'} />} />
                            <Route path='shop/brand/:brand' element={<CategoryBrowserPage lang={'ro'} />} />
                            <Route path='shop/:subcategory' element={<CategoryBrowserPage lang={'ro'} />} />
                            <Route path='shop/:subcategory/:product_name' element={<ProductPage lang={'ro'} toggleDiscountModal={toggleDiscountModal} />} />
                            <Route path='cart' element={<CartPage lang={'ro'} />} />
                            <Route path='checkout' element={<Checkout lang={'ro'} />} />
                            <Route path='profile' element={<ProfilePage lang={'ro'} />} />
                            <Route path='login' element={<Login lang={'ro'} />} />
                            <Route path='registration' element={<Registration lang={'ro'} />} />
                            <Route path='restore' element={<Restore lang={'ro'} />} />
                            <Route path='restore/:r/:e' element={<ChangePassword lang={'ro'} />} />
                            <Route path='contacts' element={<ContactPage lang={'ro'} />} />
                            <Route path='info' element={<InfoPage lang={'ro'} />} />
                            <Route path='success' element={<SuccessPage lang={'ro'} />} />
                        </Route>
                        <Route path='ru'>
                            <Route index element={<MainPage lang={'ru'} />} />
                            <Route path='shop' element={<CategorySelectPage lang={'ru'} />} />
                            <Route path='shop/brand/:brand' element={<CategoryBrowserPage lang={'ru'} />} />
                            <Route path='shop/:subcategory' element={<CategoryBrowserPage lang={'ru'} />} />
                            <Route path='shop/:subcategory/:product_name' element={<ProductPage lang={'ru'} toggleDiscountModal={toggleDiscountModal} />} />
                            <Route path='cart' element={<CartPage lang={'ru'} />} />
                            <Route path='checkout' element={<Checkout lang={'ru'} />} />
                            <Route path='profile' element={<ProfilePage lang={'ru'} />} />
                            <Route path='login' element={<Login lang={'ru'} />} />
                            <Route path='registration' element={<Registration lang={'ru'} />} />
                            <Route path='restore' element={<Restore lang={'ru'} />} />
                            <Route path='restore/:r/:e' element={<ChangePassword lang={'ru'} />} />
                            <Route path='contacts' element={<ContactPage lang={'ru'} />} />
                            <Route path='info' element={<InfoPage lang={'ru'} />} />
                            <Route path='success' element={<SuccessPage lang={'ru'} />} />
                        </Route>

                        <Route index element={<MainPage />} />
                        <Route path='/shop' element={<CategorySelectPage />} />
                        <Route path='/shop/brand/:brand' element={<CategoryBrowserPage />} />
                        <Route path='/shop/:subcategory' element={<CategoryBrowserPage />} />
                        <Route path='/shop/:subcategory/:product_name' element={<ProductPage toggleDiscountModal={toggleDiscountModal} />} />
                        <Route path='/cart' element={<CartPage />} />
                        <Route path='/checkout' element={<Checkout />} />
                        <Route path='/profile' element={<ProfilePage />} />
                        <Route path='/login' element={<Login />} />
                        <Route path='/registration' element={<Registration />} />
                        <Route path='/restore' element={<Restore />} />
                        <Route path='/restore/:r/:e' element={<ChangePassword />} />
                        <Route path='/contacts' element={<ContactPage />} />
                        <Route path='/info' element={<InfoPage />} />
                        <Route path='/success' element={<SuccessPage />} />

                        <Route path='*' element={<PageNotFound />} />
                    </Routes>
                </CSSTransition>
            </TransitionGroup>
            <FloatButton onClick={toggleDiscountModal} />
            <ScrollToTopButton />
            <Footer />
            {!areCookiesAccepted && <Cookies onAccept={acceptCookiesHandler} />}
            {!isAgeChecked && <AgeWarningModal onClose={warningModalCloseHandler} />}
            {isDiscountModalOpened && <TimerModal discountTimerSeconds={timerSeconds} onClose={toggleDiscountModal} />}
        </HelmetProvider>
  );
}

export default App;

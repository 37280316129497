import Feature from "./Feature";
import BestPricesIcon from "../../assets/icons/BestPricesIcon";
import OfficialDistributorIcon from "../../assets/icons/OfficialDistributorIcon";
import DeliveryIcon from "../../assets/icons/DeliveryIcon";
import SuccessIcon from "../../assets/icons/SuccessIcon";
import SectionCenterHeader from "../../components/UI/Headers/SectionCenterHeader";
import Line from "../../components/UI/Line";

import './FeaturesBlock.css';
import {useTranslation} from "react-i18next";

const FeaturesBlock = () => {
    const { t } = useTranslation();

  return (
      <div className='features-block' itemScope itemType="http://schema.org/ItemList">
          <SectionCenterHeader>{t('mainPage.featuresBlock.header')}</SectionCenterHeader>
          <div className='features-block__table d-flex'>
              <div className='d-flex features-block__row w-100'>
                <Feature
                    title={t('mainPage.featuresBlock.bestPriceTitle')}
                    text={t('mainPage.featuresBlock.bestPriceText')}
                    icon={<BestPricesIcon />}
                />
                <Feature
                    title={t('mainPage.featuresBlock.officialDistributorTitle')}
                    text={t('mainPage.featuresBlock.officialDistributorText')}
                    icon={<OfficialDistributorIcon />}
                />
              </div>
              <div className='d-flex features-block__row w-100'>
                  <Feature
                      title={t('mainPage.featuresBlock.fastDeliveryTitle')}
                      text={t('mainPage.featuresBlock.fastDeliveryText')}
                      icon={<DeliveryIcon />}
                  />
                  <Feature
                      title={t('mainPage.featuresBlock.returnProductTitle')}
                      text={t('mainPage.featuresBlock.returnProductText')}
                      icon={<SuccessIcon />}
                  />
              </div>
          </div>
          <Line />
      </div>
  );
}

export default FeaturesBlock;
import {useTranslation} from "react-i18next";

import Container from "../../container/Container";
import Row from "../../grid/Row";
import Col from "../../grid/Col";
import HollowButton from "../../UI/Buttons/HollowButton";

import './Instagram.css';
import Inst from '../../../assets/img/Instagram_subscribe.png';
import Fume from '../../../assets/img/fume.png';

const Instagram = () => {
    const { t } = useTranslation();

    return (
        <section className='instagram-subscribe' style={{backgroundImage: `url(${Fume})`}} itemScope itemType={'http://schema.org/FollowAction'}>
            <Container>
                <Row>
                    <Col col={6} className='d-flex align-items-center justify-content-center'>
                        <div>
                            <h2 className='instagram-subscribe__header primary-text' itemProp={'name'}>{t('InstagramBlock.header')}</h2>
                            <p className='instagram-subscribe__text light-text' itemProp={'description'}>
                                {t('InstagramBlock.subheader')}
                            </p>
                            <HollowButton
                                itemProp={'url'}
                                href={'https://www.instagram.com/torsedor.md/'}
                                target={'_blank'}
                                text={t('InstagramBlock.subscribeButton')}
                            />
                        </div>
                    </Col>
                    <Col col={6}>
                        <img src={Inst} width='605' height='653' alt='' itemProp={'image'} />
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Instagram;
import { Link } from "react-router-dom";

import './Breadcrumbs.css';

const Breadcrumbs = (props) => {
    const breadcrumbItems = props.hierarchy.map((item, index) => {
        if (item.url !== undefined) return (
            <div className='breadcrumbs__item' key={index} itemScope itemProp={'itemListElement'} itemType={'https://schema.org/ListItem'}>
                <meta itemProp={'name'} content={item.name} />
                <Link className='breadcrumbs__item' itemProp={'url'} to={item.url}>{item.name}</Link>
            </div>
        );
        return <span itemProp={'itemListElement'} className='breadcrumbs__item' key={index}>{ item.name }</span>;
    });

    return (
      <nav className='breadcrumbs' itemScope itemType="https://schema.org/BreadcrumbList">
          { breadcrumbItems }
      </nav>
    );
};

export default Breadcrumbs;
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

import './TopBrand.css';

const TopBrand = (props) => {
    const {i18n} = useTranslation();
    return (
            <Link to={'/' + i18n.resolvedLanguage + '/shop/brand/' + props.url} className='top-brand'>
                <span className='top-brand'>
                    {props.logo && <img itemProp={'image'} src={props.logo} width='150' height='65' alt='top brand logo' />}
                    {props.svgLogo}
                </span>
            </Link>
        
    );
};

export default TopBrand;
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

import Container from "../../container/Container";
import Row from "../../grid/Row";
import Col from "../../grid/Col";
import Ball from "../../Ball";

import './Footer.css';
import Logo from "../../../assets/icons/Logo";
import InstagramLogo from "../../../assets/icons/InstagramLogo";
import TelegramLogo from "../../../assets/icons/TelegramLogo";

const Footer = () => {
    const { t, i18n } = useTranslation();

    return (
        <footer className='p-relative'>
            <Container>
                <Row>
                    <Col sm={12} md={12} lg={3} className='footer__margin footer__first-block'>
                        <div>
                            <Logo className='footer__logo' />
                        </div>
                        <a href='tel:+37379556644' className='primary-text footer__text'>+373 795 56 644</a>
                        <div className="footer__text"><InstagramLogo />&nbsp;&nbsp;<TelegramLogo /></div>
                        
                    </Col>
                    <Col sm={12} md={6} lg={6} className='footer__margin'>
                        <Link to={'/' + i18n.resolvedLanguage + '/info'} className='primary-text footer__text'>{t('footer.termsAndConditions')}</Link>
                        <Link to={'/' + i18n.resolvedLanguage + '/contacts'} className='primary-text footer__text'>{t('footer.contactsLink')}</Link>
                        <Link to={'/' + i18n.resolvedLanguage + '/shop'} className='primary-text footer__text'>{t('footer.shopLink')}</Link>
                        <a href={'/sitemap.xml'} target={'_blank'} className='primary-text footer__text'>{t('footer.sitemap')}</a>
                    </Col>
                    <Col sm={12} md={12} lg={3} className='footer__margin footer__restriction-block'>
                        <div className='text-center footer__margin' style={{order: 1}}>
                            <span className='footer__restriction secondary-text'>18+</span>
                        </div>
                        <p className='footer__text gray-text footer__margin footer__restriction-text' >
                            {t('footer.restrictionText')}
                        </p>
                    </Col>
                    <Col col={12} className='footer__margin text-center'>
                        <a href='https://www.amark.pro/' target='_blank' rel='noreferrer' className='white-text footer__copyright footer__margin'>DESIGNED AND DEVELOPED BY AMARK.PRO</a>
                        <p className='dark-text footer__copyright'>Copyright 2022-{new Date().getFullYear()}</p>
                    </Col>
                </Row>
                <Ball
                    background='#FE3D00'
                    top='0'
                    left='-150px'
                />
            </Container>
        </footer>
    );
};

export default Footer;